import React, { useState } from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import styled from 'styled-components';
import Logo from '../assets/images/cinema-with-popcorn.jpg';
import Logo1 from '../assets/images/freepik__expand__34929.png';
import Logo2 from '../assets/images/vision.png';
import Logo3 from '../assets/images/boy-with-vr-glasses-cinema.jpg';
import Logo4 from '../assets/images/person-paying-with-its-smartphone-wallet-app.jpg';
import Logo5 from '../assets/images/19197121.jpg';
import Logo6 from '../assets/images/creditcard.jpg';
import contactbg from '../assets/images/business-background-woman-showing-invisible-object-hand-gesture.jpg';

// Section Styles
const Section = styled.section`
  padding: 80px 50px;
  background-color: #fff;
  color: #333;
  text-align: center;
  animation: fadeIn 1s ease-in;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  h2 {
    font-size: 3rem;
    color: #FF6600;
    margin-bottom: 30px;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }
`;

// Expandable Section Styles
const ExpandableSection = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  padding: 30px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffebcc;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ExpandableText = styled.div`
  flex: 3;
  padding: 20px;
  text-align: left;
  font-family: 'Open Sans', sans-serif;

  h3 {
    font-size: 2rem;
    color: #FF6600;
    margin-bottom: 20px;
    font-weight: bold;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  button {
    margin-top: 20px;
    background-color: #FF6600;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #FF8C00;
    }
  }
`;

const AboutImage = styled.img`
  flex: 1;
  max-width: 240px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

// Card Container for Features
const FeatureCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
`;

// Card Style
const FeatureCard = styled.div`
  background: #fff;
  padding: 30px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    color: #FF6600;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
  }

  button {
    background-color: #FF6600;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #FF8C00;
    }
  }
`;

// Modal/Dialog Style
const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background-color: #fff;
  padding: 40px;
  width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 2rem;
    color: #FF6600;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  button {
    background-color: #FF6600;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;

    &:hover {
      background-color: #FF8C00;
    }
  }

   /* Media Query for Small Screens */
  @media (max-width: 768px) {
    width: 90%;  /* Make the dialog width responsive */
    padding: 20px;
  }
`;

const PricingCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
`;

// Redesigned Pricing Cards
const PricingCard = styled.div`
  background: linear-gradient(145deg, #ff6600, #ff8c00);
  padding: 30px;
  width: 300px;
  text-align: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #fff;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    
  }

  button {
    background-color: #fff;
    color: #ff6600;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff8c00;
    }
  }
`;
const PricingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  text-align: center;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #FF6600;
    color: white;
  }

  td {
    background-color: #f9f9f9;
  }
 @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap; /* Make the table scrollable on small screens */
  }
  
`;

const ContactSection = styled.section`
padding: 80px 50px;
background-image: url(${contactbg});
background-size: cover;
background-position: center;
color: white;
text-align: center;
min-height: 100vh;
`;

const Title = styled.h2`
font-size: 3rem;
color: #FF6600;
margin-bottom: 30px;
`;

const Description = styled.p`
font-size: 1.5rem;
max-width: 800px;
margin: 0 auto 30px;
line-height: 1.6;
text-align: justify;
`;

const ContactCard = styled.div`
background: rgba(0, 0, 0, 0.9);
padding: 30px;
margin-bottom: 30px;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
text-align: left;
max-width: 600px;
margin-left: auto;
margin-right: auto;

h3 {
  font-size: 2rem;
  color: #FF6600;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

a {
  color: #FF6600;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover {
    color: #ff8c00;
  }
}
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #FF6600;
  padding-top: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  animation: fadeIn 1s ease-in-out;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const HighlightItem = styled.div`
  text-align: center;
  flex: 1;
  font-size: 2.5rem;
  font-weight: bold;
  opacity: 0;
  animation: slideIn 1.5s ease-in-out forwards;
  
  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  h4 {
    font-size: 1.5rem;
    margin-top: 10px;
    text-transform: uppercase;
    opacity: 0;
    animation: fadeInText 1s ease-in-out forwards;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeInText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Hover effect */
  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }

  /* Reverse pyramid layout for larger screens */
  @media (min-width: 768px) {
    &:nth-child(1) {
      order: 3;
    }

    &:nth-child(2) {
      order: 2;
    }

    &:nth-child(3) {
      order: 1;
    }
  }
`;

const HomePage = () => {
  const [expanded, setExpanded] = useState({});

  const toggleSection = (section) => {
    setExpanded((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const [isDialogOpenPricing, setDialogOpenPricing] = useState(false);
  const [dialogContentPricing, setDialogContentPricing] = useState('');

 

  const openDialog = (feature) => {
    setDialogContent(featureDetails[feature]);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const openDialogPricing = (content) => {
    setDialogContentPricing(pricingDetails[content]);
    setDialogOpenPricing(true);
  };

  const closeDialogPricing = () => {
    setDialogOpenPricing(false);
    setDialogContentPricing('');
  };

  const featureDetails = {
    "QR Code-Based Ordering": "Customers can scan a unique QR code placed on tables, seats, or screens. The QR code instantly redirects to a digital menu without the need for any app download. Users can browse, customize, and place orders seamlessly.",
    "Real-Time Menu Management": "Restaurants and vendors can update menu items, availability, and prices in real-time. Automatically hides unavailable items to prevent order cancellations. Supports multiple menu categories for easy navigation.",
    "Seamless Payment Integration": "Supports various payment methods, including UPI, credit/debit cards, and digital wallets. Provides secure transactions with encryption and fraud protection. Offers payment receipts and order tracking for transparency.",
    "Smart Order Queue Management": "Orders are automatically sorted based on preparation time and priority. Real-time notifications are sent to both customers and kitchen staff. Reduces congestion by ensuring systematic order fulfillment.",
    "AI-Powered Order Recommendations": "Analyzes customer preferences and order history to suggest popular dishes. Dynamic upselling and cross-selling strategies improve sales. Personalized recommendations increase customer engagement.",
    "Real-Time Inventory Tracking": "Tracks stock levels and notifies vendors about low inventory. Prevents overselling of out-of-stock items. Generates automated inventory reports for better supply chain management.",
    "Multi-Vendor Support": "Enables food courts, stadiums, and transportation hubs to list multiple vendors. Customers can order from different vendors in a single session. Centralized payment and order tracking for convenience.",
    "Order Status & Notifications": "Customers receive real-time updates on order status (Accepted, Preparing, Ready, Delivered). SMS and push notifications ensure timely communication. Staff can manage and update order status from a vendor dashboard.",
    "Contactless Dining & Takeaway": "Customers can choose between dining at their table or picking up their orders. Eliminates the need for physical menus and cash transactions. Reduces wait times and improves overall dining efficiency.",
    "Analytics & Business Insights": "Dashboard with key performance metrics such as sales, peak hours, and customer preferences. Data-driven insights help businesses optimize pricing and inventory management. Detailed reports available for download.",
    "Customizable Branding": "Businesses can personalize the interface with their logo, colors, and theme. White-label solutions available for premium partners. Custom domain integration for large-scale enterprises.",
  };


  const pricingDetails = {
    "Subscription-Based Model": (
      <div>
        <h3>Subscription-Based Model</h3>
        <p>
          Ideal for large-scale restaurants, theaters, hotels, and stadiums that prefer predictable expenses and unlimited usage. This model provides fixed monthly or yearly costs for unlimited transactions.
        </p>
        <PricingTable>
          <thead>
            <tr>
              <th>Plan</th>
              <th>Monthly Cost</th>
              <th>Annual Cost</th>
              <th>Features Included</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Basic</td>
              <td>₹3,999</td>
              <td>₹39,999</td>
              <td>QR Ordering, Digital Menu, Payment Integration, Basic Support</td>
            </tr>
            <tr>
              <td>Standard</td>
              <td>₹7,999</td>
              <td>₹79,999</td>
              <td>All Basic Features + Analytics, Order Queue, Real-Time Inventory</td>
            </tr>
            <tr>
              <td>Premium</td>
              <td>₹14,999</td>
              <td>₹1,49,999</td>
              <td>All Standard Features + AI Recommendations, Multi-Vendor Support, White Labeling</td>
            </tr>
          </tbody>
        </PricingTable>
      </div>
    ),
    "Commission-Based Model": (
      <div>
        <h3>Commission-Based Model</h3>
        <p>
          Ideal for small vendors or startups. This model allows businesses to pay a commission per order with no upfront costs. It's great for minimizing initial costs while offering scalability as the business grows.
        </p>
        <PricingTable>
          <thead>
            <tr>
              <th>Order Volume</th>
              <th>Commission per Order</th>
              <th>Features Included</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 - 1,000 Orders</td>
              <td>5% per order</td>
              <td>Digital Menu, QR Ordering, Payment Integration</td>
            </tr>
            <tr>
              <td>1,001 - 5,000 Orders</td>
              <td>3.5% per order</td>
              <td>All Basic + Analytics, Inventory, Order Queue</td>
            </tr>
            <tr>
              <td>5,001+ Orders</td>
              <td>2% per order</td>
              <td>All Standard + AI Recommendations, Multi-Vendor Support</td>
            </tr>
          </tbody>
        </PricingTable>
      </div>
    ),
    "One-Time Installation Package": (
      <div>
        <h3>One-Time Installation Package</h3>
        <p>
          Ensure seamless integration with our one-time setup cost for hardware and software. This package includes the necessary components for a smooth launch.
        </p>
        <PricingTable>
          <thead>
            <tr>
              <th>Service</th>
              <th>Cost</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>QR Code Setup</td>
              <td>₹2,999</td>
              <td>Custom QR generation & placement</td>
            </tr>
            <tr>
              <td>POS & Dashboard Setup</td>
              <td>₹9,999</td>
              <td>Full system integration with restaurant POS</td>
            </tr>
            <tr>
              <td>Hardware Installation</td>
              <td>₹14,999</td>
              <td>Tablets, receipt printers, and networking setup</td>
            </tr>
            <tr>
              <td>White Label Branding</td>
              <td>₹19,999</td>
              <td>Custom domain, branding, and UI customization</td>
            </tr>
          </tbody>
        </PricingTable>
      </div>
    ),
  };
  return (
    <div>
      <Header />
      <HeroSection id="home" /> {/* Hero Section */}
      <Section id="about">
        <h2>About Us</h2>
        <p>
          Khrana revolutionizes food ordering by offering a seamless, contactless experience at theaters, restaurants, hotels, stadiums, and transportation hubs.
        </p>

        <HighlightContainer>
          
          <HighlightItem>
            <div style={{fontWeight:"900"}}>3</div>
            <h4>Clients Onboarded</h4>
          </HighlightItem>
          <HighlightItem>
            <div  style={{fontWeight:"900"}}>3,000+</div>
            <h4>Orders Processed</h4>
          </HighlightItem>

          <HighlightItem>
            <div  style={{fontWeight:"900"}}>10</div>
            <h4>Screens</h4>
          </HighlightItem>
        </HighlightContainer>

      </Section>

      {/* Expandable Section - Who We Are */}
      <ExpandableSection>
        <ExpandableText>
          <h3>Who We Are</h3>
          <p>
            Khrana is revolutionizing food ordering by providing a seamless, contactless experience across theaters, restaurants, hotels, and more. Our system leverages cutting-edge technology to make food ordering simple, fast, and efficient.
          </p>
          <button onClick={() => toggleSection('whoWeAre')}>Read More</button>
          {expanded['whoWeAre'] && (
            <p>
              Our QR-based ordering system enables customers to browse menus, place orders, and make secure payments with a few taps. 
            </p>
          )}
        </ExpandableText>
              <AboutImage src={Logo} alt="Who We Are" />
        
      </ExpandableSection>

      {/* Expandable Section - Mission & Vision */}
      <ExpandableSection reverse>
      <ExpandableText>
        <h3>Our Mission & Vision</h3>
        <p className="mission">
          <strong>Mission:</strong> To revolutionize food ordering with smart, efficient, and contactless solutions that enhance convenience for customers and businesses alike.
        </p>
        <p className="vision">
          <strong>Vision:</strong> To become India’s leading food-tech platform, expanding across multiple sectors, including transportation hubs for an unparalleled food ordering experience.
        </p>
       
      </ExpandableText>
      <AboutImage src={Logo1} alt="Mission and Vision" />
    </ExpandableSection>

      {/* Expandable Section - Journey & Achievements */}
      <ExpandableSection>
        <ExpandableText>
          <h3>Our Journey & Achievements</h3>
          <p>
            Khrana started with a goal to eliminate inefficiencies in food ordering. In the past two years, we've partnered with over 3 clients and processed over 3,000 orders seamlessly.
          </p>
          <button onClick={() => toggleSection('journey')}>Read More</button>
          {expanded['journey'] && (
            <p>
              We've partnered with top vendors, onboarded multiple clients, and refined our system to ensure businesses can thrive and customers enjoy smooth, error-free ordering.
            </p>
          )}
        </ExpandableText>
        <AboutImage src={Logo2} alt="Journey and Achievements" />

      </ExpandableSection>

      {/* Expandable Section - Technology & Solutions */}
      <ExpandableSection reverse>
        <ExpandableText>
          <h3>Our Technology & Solutions</h3>
          <p>
            Our platform utilizes QR-based smart ordering, secure payment integrations, real-time analytics, and AI-powered recommendations, ensuring businesses operate efficiently while offering customers a top-tier experience.
          </p>
        </ExpandableText>
        <AboutImage src={Logo3} alt="Technology and Solutions" />

      </ExpandableSection>

      <Section id="features">
        <h2>Core Features of Khrana</h2>
        <p>
          Khrana offers a comprehensive set of features designed to streamline food ordering, reduce wait times, and improve overall customer satisfaction.
        </p>

        <FeatureCardContainer>
          {/* Feature Card 1 */}
          <FeatureCard>
            <img src={Logo4} alt="Contactless Ordering" />
            <h3>Contactless Ordering</h3>
            <p>Order your favorite meals safely and conveniently with our contactless system.</p>
            <button onClick={() => openDialog('QR Code-Based Ordering')}>Learn More</button>
          </FeatureCard>

          {/* Feature Card 2 */}
          <FeatureCard>
            <img src={Logo5} alt="Real-time Order Tracking" />
            <h3>Real-time Order Tracking</h3>
            <p>Track your order progress live and enjoy peace of mind while you wait.</p>
            <button onClick={() => openDialog('Real-Time Menu Management')}>Learn More</button>
          </FeatureCard>

          {/* Feature Card 3 */}
          <FeatureCard>
            <img src={Logo6} alt="Secure Payments" />
            <h3>Secure Payments</h3>
            <p>Make secure payments effortlessly with our trusted platform.</p>
            <button onClick={() => openDialog('Seamless Payment Integration')}>Learn More</button>
          </FeatureCard>
        </FeatureCardContainer>
      </Section>

      <Section id="pricing" style={{marginTop:"-100px"}}>
        <h2>Pricing Plans</h2>
        <p>
          Khrana offers flexible pricing models designed to fit the needs of businesses large and small. Whether you're a large enterprise or a startup, we have a plan for you.
        </p>

        <PricingCardContainer>
          {/* Subscription-Based Model Card */}
          <PricingCard>
            <h3>Subscription-Based Model</h3>
            <p>Fixed monthly or yearly pricing for unlimited transactions.</p>
            <button onClick={() => openDialogPricing('Subscription-Based Model')}>Learn More</button>
          </PricingCard>

          {/* Commission-Based Model Card */}
          <PricingCard>
            <h3>Commission-Based Model</h3>
            <p>Pay per transaction based on your order volume.</p>
            <button onClick={() => openDialogPricing('Commission-Based Model')}>Learn More</button>
          </PricingCard>

          {/* One-Time Installation Card */}
          <PricingCard>
            <h3>One-Time Installation Package</h3>
            <p>Setup cost for hardware and software integration.</p>
            <button onClick={() => openDialogPricing('One-Time Installation Package')}>Learn More</button>
          </PricingCard>
        </PricingCardContainer>
      </Section>

      <ContactSection id="contact">
      <Title>Contact Us</Title>
      <Description>
        We are here to assist you with any inquiries, technical support, or business partnerships.
        Reach out to us through the following channels:
      </Description>

      <ContactCard>
        <h3>Email Support</h3>
        <p>
          For general inquiries, technical support, and partnership opportunities, contact us at: 
          <a href="mailto:support@khrana.com"> support@khrana.com</a>
        </p>
      </ContactCard>

      <ContactCard>
        <h3>Phone Support</h3>
        <p>
          Need immediate assistance? Call our customer support team: 
          <a href="tel:+916300614665"> +91-6300614665</a>
        </p>
      </ContactCard>
    </ContactSection>

      {/* Dialog for additional content */}
      <Dialog isOpen={isDialogOpen}>
        <DialogContent>
          <p>{dialogContent}</p>
          <button onClick={closeDialog}>Close</button>
        </DialogContent>
      </Dialog>


      <Dialog isOpen={isDialogOpenPricing}>
        <DialogContent>
          {dialogContentPricing}
          <button onClick={closeDialogPricing}>Close</button>
        </DialogContent>
      </Dialog>

      

      <Footer />
    </div>
  );
};

export default HomePage;
