import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/Khrana_logo_White.png';

// Header container
const HeaderContainer = styled.header`
  background-color: #000;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Show navigation links on large screens */
  .nav-links {
    display: flex;
    gap: 20px;

    a {
      color: #fff;
      text-decoration: none;
      font-size: 1.2rem;
      &:hover {
        color: #FF6600;
      }
    }
  }

  /* Show the hamburger menu on smaller screens */
  @media (max-width: 768px) {
    .nav-links {
      display: none; /* Hide navigation links on small screens */
    }
  }
`;

const LogoImage = styled.img`
  height: 50px;
`;

// Hamburger Menu
const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
  z-index: 1100; /* Ensure it is above other content */
  
  div {
    background-color: #fff;
    height: 4px;
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex; /* Show hamburger menu on small screens */
  }
`;

// Sidebar (hidden initially)
const Sidebar = styled.div`
  position: fixed;
  top: 90px; /* Start the sidebar below the header */
  right: ${({ isOpen }) => (isOpen ? '0' : '-250px')}; /* Slide from the right */
  width: 250px;
  height: 100%;
  background-color: #000;
  color: #fff;
  transition: right 0.3s ease-in-out;
  z-index: 1000;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    padding: 15px;
    &:hover {
      background-color: #FF6600;
    }
  }
`;

// Overlay (dims the background when menu is open)
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dim background */
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
`;

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <HeaderContainer>
      <LogoImage src={Logo} alt="Khrana Logo" />
      {/* Show navigation links on larger screens */}
      <div className="nav-links">
        <a href="#home" onClick={scrollToTop}>Home</a>
        <a href="#about">About</a>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#contact">Contact</a>
      </div>

      {/* Hamburger Menu visible only on small screens */}
      <Hamburger onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>

      {/* Sidebar that shows when menu is open */}
      <Sidebar isOpen={isMenuOpen}>
        <a href="#home" onClick={scrollToTop}>Home</a>
        <a href="#about">About</a>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#contact">Contact</a>
      </Sidebar>

      {/* Overlay that dims the background when menu is open */}
      <Overlay isOpen={isMenuOpen} onClick={toggleMenu} />
    </HeaderContainer>
  );
};

export default Header;
