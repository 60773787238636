import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/Khrana_logo_White.png';
import { FaInstagram } from 'react-icons/fa'; // Instagram icon from react-icons

const FooterContainer = styled.footer`
  background-color: #000;
  color: white;
  padding: 20px;
  text-align: center;
`;

const LogoImage = styled.img`
  height: 50px;
`;

const InstagramLink = styled.a`
  color: white;
  font-size: 14px;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;

  &:hover {
    color: #FF6600; 
  }
`;

const Footer = () => (
  <FooterContainer>
    <LogoImage src={Logo} alt="Khrana Logo" />
    <p>&copy; 2025 Khrana. All Rights Reserved.</p>
    <InstagramLink href="https://www.instagram.com/khranaeats?igsh=ZTVyZ3kxbzltd2dh" target="_blank" rel="noopener noreferrer">
      <FaInstagram style={{ marginRight: '8px' }} />
      Follow us on Instagram
    </InstagramLink>
  </FooterContainer>
);

export default Footer;
