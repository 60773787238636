// src/components/HeroSection.js
import React from 'react';
import styled from 'styled-components';
import heroImage from '../assets/images/homepage_bg.jpeg'; 

const HeroContainer = styled.section`
  position: relative;
  background-color: #FF6600;
  color: #fff;
  padding: 0;  /* Remove padding to ensure the background image fills the entire section */
  height: 100vh;  /* Make the container take full viewport height */
  text-align: center;
  animation: fadeIn 1s ease-in;
  width: 100%;
  box-sizing: border-box;
  
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  
    &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

`;

const TextOverlay = styled.div`
  position: absolute;
  top: 50%;  /* Move the text down from the top */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    background-color: rgba(255, 119, 0, 0.23);
    padding: 10px;
    animation: slideIn 2s ease-out;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 30px;
    animation: slideIn 2.5s ease-out;
  }

  /* Hover Animation */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const HeroButton = styled.button`
  position: absolute;  /* Position the button absolutely at the bottom of the image */
  bottom: 20px;  /* Set distance from the bottom */
  left: 50%;
  transform: translateX(-50%);  /* Center the button horizontally */
  background-color: #FF6600;
  color: #fff;
  padding: 15px 25px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  z-index: 1000;
  &:hover {
    background-color: rgb(255, 255, 255);
    color: #FF6600;
  }
`;

const HeroSection = () => {
  // Scroll to the About Us section when clicking "Get Started"
  const scrollToAbout = () => {
    const aboutSection = document.getElementById("about");
    aboutSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <HeroContainer id="home">
      <TextOverlay>
        <h1>Scan, Order, Enjoy – Contactless Food Ordering Made Easy!</h1>
        <p>Experience hassle-free food ordering at theaters, restaurants, hotels, stadiums, and transport hubs.</p>
      </TextOverlay>
      <HeroButton onClick={scrollToAbout}>Get Started</HeroButton>
    </HeroContainer>
  );
};

export default HeroSection;
